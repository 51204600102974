import { SerialisationError } from "./error";
import TrapdoorManager from "./TrapdoorManager";
import CONFIG from "../config";
import { useState, useEffect } from 'react';

export const IN_PRODUCTION = process.env.NODE_ENV === "production"

export const pathify = function () { //factory function for new pathify
  /*
   * from server class  tainted.Pathification(object):
  """
  Normalise a string for use in eg in an (unescaped) URL or anywhere else that we want a very tame string
  Replacement for original which would reject strings with chars it didn't know about  -this version just strips them
  Note: caution should be exercised when applying this routine to keys (or anything else unique).
      There is no guarauntee that two unique strings won't pathify to the same string !! (eg we simply drop single quote)

  """
  IF MODIFYING THIS CODE -YOU MUST ALSO MODIFY THE SERVER VERSION
   */
  const subs: [RegExp, string][] = [
    [new RegExp("-+", "g"), " "], /*dashes become one space*/
    [new RegExp(" +", "g"), "_"], /*one or more spaces reduced to single underscore*/
    [new RegExp("&", "g"), "_and_"], /*ampersand to _and_ */
    [new RegExp("@", "g"), "_at_"],  /* /@ to _at_*/
    [new RegExp("\\.", "g"), "_dot_"],  /* . to _dot_  (note not identical to python -extra espace char needed) */
    [new RegExp("[^a-zA-Z0-9_]+", "g"), ""]  /* (finally  -anything other than alphanum and underscore removed*/
  ];
  return function (strDirty: string): string {
    let strMut = strDirty; //could not bring myself to repeatedly mutate the argument
    for (let i = 0; i < subs.length; i++) {
      const sub = subs[i];
      strMut = strMut.replace(sub[0], sub[1]);
    }
    return strMut.toLowerCase();
  };
}();

export const mkStringGenerator = function (candidateCharStr_opt?: string) {
  let chars: string
  if (typeof candidateCharStr_opt === 'undefined') {
    chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  }
  else {
    chars = candidateCharStr_opt;
  }

  return function (len: number) {
    var buff = [];
    for (var i = 0; i < len; i++) {
      var iChar = Math.floor(Math.random() * chars.length);
      buff.push(chars.substring(iChar, iChar + 1));
    }
    return buff.join('');
  };
};

export const callIfNotUndefined = <T>(callable?: (...args: any) => T, ...args: any[]): T | undefined => {
  if (callable !== undefined) {
    return callable(...args)
  }
  return undefined
}

export const callIfNotNull = <T>(callable: ((...args: any) => T) | null, ...args: any[]): T | null => {
  if (callable !== null) {
    return callable(...args)
  }
  return null
}

/* NEVER use for any security-like purpose*/
export const isTgclEmail = (emailStr: string): boolean => {
  const value = !!emailStr.match(/@tgcl.co.nz$|@ezlunch.co.nz$/)

  if (value && TrapdoorManager.get("__kindo_ignore_tgcl_email")) {
    return false
  }

  return value
}

export const isTaxaccEmail = (emailStr: string): boolean => {
  const value = !!emailStr.match(/@taxgift.co.nz$|@taxgift.co.nz$|@streamlinebusiness.net$|@supergenerous.co.nz/)

  if (value && TrapdoorManager.get("__kindo_ignore_tgcl_email")) {
    return false
  }

  return value
}

/*
 * From ez/util.js
 * return true if either of the following is true
 * a. old school adminObjOrEmailStr or adminObjOrEmailStr.email is in ez.conf.default_admin_list
 * b. new school (and as for p7) ez.conf.default_admin_list is undefined and adminObjOrEmailStr or adminObjOrEmailStr.email  is NOT a tgcl address
 * replacing the default_admin_list is a WIP
 * code using it should be converted to use this function
 * eventually we can remove conf.default_admin_list
 */
export const isAdminListable = (adminEmail: string): boolean => {
  if (ez.conf.default_admin_list === undefined) {
    return !isTgclEmail(adminEmail)
  }
  else {
    return ez.conf.default_admin_list.indexOf(adminEmail) === -1;
  }
}

export const isNextCPageValid = (cPageNext: string): boolean => {
  return CONFIG.cPage.indexOf(cPageNext) !== -1;
}

export const isSchoolAdmin = (familyOptionSchool: string): boolean => {
  return !!familyOptionSchool;
}

export const sortToSpec = <T, K>(array: T[], spec: K[], keyOf: (object: T) => K): T[] => {
  /*
   * Copies array and does a sort to match the order of the keys on the spec.
   * Uses keyOf function to get the key of each object in the array.
   * Ignores keys in the spec that do not refer to any object in the array.
   * For objects in the array that are not in the spec, they are left at the end of the result in their original order.
   */
  const arrayKeysMap: Map<K, number> = new Map(array.map((v, i) => [keyOf(v), i]))
  const result: T[] = []
  const visitedIndices: Set<number> = new Set()

  for (const key of spec) {
    const index = arrayKeysMap.get(key)

    if (index !== undefined) {
      visitedIndices.add(index)
      result.push(array[index])
    }
  }

  if (visitedIndices.size !== array.length) {
    // There are some extra items that aren't in the spec.
    // Append them to the end of the result *in order*
    for (let i = 0; i < array.length; i++) {
      if (!visitedIndices.has(i)) {
        // Haven't visited yet. Push to the result
        result.push(array[i])
      }
    }
  }

  return result
}

const findIndexWhereIndex = <T>(arr: T[], whereCondition: (obj: T) => boolean, startIndex: number): number => {
  for (let i = startIndex; i < arr.length; i++) {
    if (whereCondition(arr[i])) {
      return i
    }
  }

  return -1
}

export const moveItemLeft = <T>(arr: T[], itemIndex: number, insertIndex: number): void => {
  if (itemIndex < insertIndex) {
    throw new Error("This function can only move items left, so itemIndex must be >= insertIndex.")
  }

  if ((itemIndex - insertIndex) === 0) {
    return;
  }
  else if ((itemIndex - insertIndex) === 1) {
    // trivial swap
    [arr[itemIndex], arr[insertIndex]] = [arr[insertIndex], arr[itemIndex]];
  }
  else {
    let currentItem = arr[insertIndex];
    arr[insertIndex] = arr[itemIndex];

    for (let i = insertIndex + 1; i <= itemIndex; i++) {
      [arr[i], currentItem] = [currentItem, arr[i]];
    }
  }
}

export const inPlaceSortToSpec = <T, K>(array: T[], spec: K[], keyOf: (object: T) => K): void => {
  /*
   * Does an in-place sort on array to match the order of the keys on the spec.
   * Probably significantly slower than the out-of-place sortToSpec function. TODO: surely this can be optimised a bit??
   * Uses keyOf function to get the key of each object in the array.
   * Ignores keys in the spec that do not refer to any object in the array.
   * For objects in the array that are not in the spec, they are left at the end of the result in original order.
   */
  let boundaryIndex: number = 0

  for (const key of spec) {
    const index = findIndexWhereIndex(array, (obj) => keyOf(obj) === key, boundaryIndex)

    if (index !== -1) {
      moveItemLeft(array, index, boundaryIndex)
      //[array[index], array[boundaryIndex]] = [array[boundaryIndex], array[index]]  // quicker, but loses original order.
      boundaryIndex++
    }
  }
}

export const deserialiseStringEnum = <T extends { [key: string]: string }>(e: T, value: string) => {
  const entries = Object.entries(e)
  for (const [k, v] of entries) {
    if (v === value) {
      return e[k]
    }
  }

  throw new SerialisationError(`Failed to deserialise value: ${value}.`)
}

export const isProductionHost = (): boolean => {
  return document.location.host === 'shop.tgcl.co.nz';
}

export const isSecureConnection = (): boolean => {
  return ('https:' === document.location.protocol || !isProductionHost())
}

/**
 * Merges many (optionally null or undefined) class names into a single valid 'className' string.
 *
 * Undefined or null values are skipped.
 */
export const mergeClassNames = (...classNames: (string | undefined | null)[]): string => {
  const actualNames = classNames.filter((name) => name !== undefined && name !== null && name !== '')
  return actualNames.join(' ')
}

/**
 * Randomize array
 */
export const shuffleArray = function (array?: any) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
};


export const deleteCookie = (name: string, path = '/') => {
  const now = new Date();
  document.cookie = `${name}=; max-age=0; path=${path}; secure; expires=${now.toUTCString()}; domain=.kindo.co.nz;`;
};

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};

export const setCookie = (name: string, value: string, days: number = 7, path = '/', domain = '') => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value}; path=${path}; secure; expires=${date.toUTCString()}; domain=${domain};`;
}

export const generateProductionListDates = () => {
  const currentDate = new Date();
  const dateArray = [];

  function isWeekend(date) {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  let startDate = new Date(currentDate);
  startDate.setDate(startDate.getDate() - 7);

  let endDate = new Date(currentDate);
  endDate.setDate(endDate.getDate() + 13);

  while (startDate <= endDate) {
    if (!isWeekend(startDate)) {
      dateArray.push(new Date(startDate));
    }
    startDate.setDate(startDate.getDate() + 1);
  }

  return dateArray;
}

export const getPhoneDetails = (telephone?: string) => {
  const split = telephone?.split('-');
  if (split) {
    const phoneNumberSplit = split[1]?.split('/') || [];
    return {
      phoneLabel: split[0] === 'mob' ? 'Mobile' : 'Landline',
      phoneCode: split[0],
      phoneNumber: phoneNumberSplit[0] || '',
      phoneExt: phoneNumberSplit[1] || ''
    }
  }
  return {
    phoneCode: '',
    phoneLabel: '',
    phoneNumber: '',
    phoneExt: ''
  };
}