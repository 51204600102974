export const pathify = function () { //factory function for new pathify
  /*
   * from server class  tainted.Pathification(object):
  """
  Normalise a string for use in eg in an (unescaped) URL or anywhere else that we want a very tame string
  Replacement for original which would reject strings with chars it didn't know about  -this version just strips them
  Note: caution should be exercised when applying this routine to keys (or anything else unique).
      There is no guarauntee that two unique strings won't pathify to the same string !! (eg we simply drop single quote)

  """
  IF MODIFYING THIS CODE -YOU MUST ALSO MODIFY THE SERVER VERSION
   */
  const subs: [RegExp, string][] = [
    [new RegExp("-+", "g"), " "], /*dashes become one space*/
    [new RegExp(" +", "g"), "_"], /*one or more spaces reduced to single underscore*/
    [new RegExp("&", "g"), "_and_"], /*ampersand to _and_ */
    [new RegExp("@", "g"), "_at_"],  /* /@ to _at_*/
    [new RegExp("\\.", "g"), "_dot_"],  /* . to _dot_  (note not identical to python -extra espace char needed) */
    [new RegExp("[^a-zA-Z0-9_]+", "g"), ""]  /* (finally  -anything other than alphanum and underscore removed*/
  ];
  return function (strDirty: string): string {
    let strMut = strDirty; //could not bring myself to repeatedly mutate the argument
    for (let i = 0; i < subs.length; i++) {
      const sub = subs[i];
      strMut = strMut.replace(sub[0], sub[1]);
    }
    return strMut.toLowerCase();
  };
}();

// Util to parse the file upload text response
export const parseFileUploadResponse = (resText: string) => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(resText, "text/html")
  const resTextArea = dom.getElementsByTagName("textarea").item(0)

  if (resTextArea === null) {
    throw new Error('Could not process response, but the upload may have worked. Please try reloading the page.');
  }

  const resValue = resTextArea.value.replace(/'/g, '"')
  const parsedResponse = JSON.parse(resValue)

  if (parsedResponse.errors) {
    throw new Error('Failed to upload file.');
  }

  return parsedResponse
}

export const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift();
  }
};
