import { getCookie } from "./util";

export default function fetcher(url: string, options?: RequestInit) {
  // Mobile app does not have document object
  const csrftoken = window.document ? getCookie('csrftoken') : '';

  return fetch(url, {
    ...options,
    headers: {
      ...options?.headers,
      ...(csrftoken ? { 'X-CSRFToken': csrftoken } : {}),
    },
    credentials: 'include',
  });
}