import { PageNavigationLink } from "./common_component/page/PageNavigation";

const DEFAULT_SHOP_NAV_LINKS: PageNavigationLink[] = [
  {
    url: 'q2.shtml',
    title: 'home',
    icon: 'fd/img/icon/Kindo_icons-home.svg'
  },
  {
    url: 'my_account.shtml',
    title: 'top up account',
    icon: 'fd/img/icon/Kindo_icons-card3.svg'
  },
  {
    url: 'my_details.shtml',
    title: 'my details',
    icon: 'fd/img/icon/Kindo_icons-person.svg'
  },
  {
    url: 'my_orders.shtml',
    title: 'my orders',
    icon: 'fd/img/icon/Kindo_icons-order.svg'
  },
];

const SHOP_URLS = {
  home: "q2.shtml",
  schoolAdmin: "/app/admin",
  myDetails: "my_details.shtml",
  taxaccEditor: "taxacc_editor.shtml"
}

const DEFAULT_PARTNER_NAV_LINKS: PageNavigationLink[] = [
  {
    url: '',
    onClick: () => {
      window.open('/app/shop?partner_preview', '_blank');
    },
    title: 'preview',
  },
  {
    url: 'partner_landing.shtml',
    title: 'home',
    icon: 'fd/img/icon/Kindo_icons-home.svg'
  },
];

export const CONFIG = {
  defaultShopDropdownNavLinks: [
    ...DEFAULT_SHOP_NAV_LINKS,
    {
      url: 'calendar.shtml',
      title: 'calendar',
      icon: 'fd/img/icon/Kindo_icons-calendar.svg'
    }
  ] as PageNavigationLink[],
  defaultShopNavLinks: DEFAULT_SHOP_NAV_LINKS,

  shopUrls: SHOP_URLS,

  defaultPartnerDropdownNavLinks: [
    ...DEFAULT_PARTNER_NAV_LINKS,
  ] as PageNavigationLink[],
  defaultPartnerNavLinks: DEFAULT_PARTNER_NAV_LINKS,

  partnerHelpUrl: 'https://support.kindo.co.nz/',
  shopHelpUrl: 'https://support.mykindo.co.nz/',
  adminHelpUrl: 'https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support',
  tgclBusinessAgreement: 'TGCL_Business_Solutions_Agreement2021_20210107.pdf',
  kindoServiceTerms: 'TGCL-Kindo_Service_Terms_2021_20210107.pdf',
  eStoreServiceTerms: 'TGCL_e-store_Service_Terms2020_20200402.pdf',

  cPage: [
    "checkout",
    "my_account"
  ]
};

export default CONFIG;