/*
* One place to manage enable/disable of secret trapdoors.
*/

class TrapdoorManager {
    set(name: string, enabled: boolean = true): void {
        window.localStorage.setItem(name, enabled ? 'yes' : 'no')
    }

    get(name: string, {default: defaultValue}: {default?: boolean} = {}): boolean {
        const value = window.localStorage.getItem(name)
        if (value === null && defaultValue !== undefined) {
            return defaultValue
        }
        return value === "yes"
    }
}

const instance = new TrapdoorManager()

// @ts-ignore
window.trapdoors = instance

export default instance