import 'core-js/features/promise';

export default function loadPolyfills() {
    const fillFetch = () => new Promise((resolve) => {
        if ('fetch' in window) return resolve();

        import('whatwg-fetch').then(() => resolve());
    })

    const fillCore = () => new Promise((resolve) => {
        if (
            'startsWith' in String.prototype &&
            'endsWith' in String.prototype &&
            'keys' in Object &&
            'entries' in Object &&
            'includes' in Array.prototype
        ) return resolve()

        import('core-js').then(() => resolve());
    })

    const fillMapSet = () => new Promise((resolve) => {
        if (
            'Map' in window &&
            'Set' in window
        ) return resolve()

        Promise.all([
            import('core-js/features/map'),
            import('core-js/features/set')
        ]).then(() => resolve())
    })

    return Promise.all([
        fillFetch(),
        fillMapSet(),
        fillCore()
    ])
};