interface NetworkErrorProps {
    primaryMessage: string;
    secondaryMessage?: string;
    detailMessage?: string;
    wrappedError?: any;
    context?: string;
}

export const PROBLEM_PERSISTS_MESSAGE = "If the problem persists, please contact the helpdesk."

export class KindoNetworkError extends Error {
    get name(): string {
        return "KindoNetworkError"
    }

    get message(): string {
        return `${this.name}: ${this.primaryMessage} - ${this.secondaryMessage}`
    }

    readonly primaryMessage: string
    readonly secondaryMessage?: string
    readonly detailMessage?: string
    readonly context?: string
    readonly wrappedError?: any

    constructor({primaryMessage, secondaryMessage, detailMessage, wrappedError, context}: NetworkErrorProps) {
        super();
        this.primaryMessage = primaryMessage
        this.secondaryMessage = secondaryMessage
        this.detailMessage = detailMessage
        this.context = context
        this.wrappedError = wrappedError
    }

    toString(): string {
        return `<${this.name} (primaryMessage: \`${this.primaryMessage}\`, secondaryMessage: \`${this.secondaryMessage}\`, detailMessage: \`${this.detailMessage}\`, wrappedError: \`${this.wrappedError}\`)>`
    }
}

interface ServerErrorProps {
    rtype?: string
    message?: string
    name?: string
    secondaryMessage?: string
    detailMessage?: string
    context?: string
}
interface ServerErrorJson {
    message?: string
    name?: string
    rtype?: string
    context?: string
}
export class KindoServerError extends KindoNetworkError {
    readonly rtype?: string

    get message(): string {
        return `${this.name}: ${this.errorMessage ?? ''}`
    }
    readonly errorMessage?: string

    get name(): string {
        return this.errorName ?? 'KindoServerError'
    }
    readonly errorName?: string

    constructor({rtype, message, name, secondaryMessage, detailMessage, context}: ServerErrorProps) {
        super({
            primaryMessage: "Oops. An error has occurred",
            secondaryMessage: (((message ?? '').length === 0) ? secondaryMessage : message) ?? `Sorry about that.\n${PROBLEM_PERSISTS_MESSAGE}`,
            detailMessage: detailMessage ?? `Error name: ${name ?? 'unknown'}`,
            context: context
        })
        this.rtype = rtype
        this.errorMessage = message
        this.errorName = name
    }

    static fromJson(json: ServerErrorJson): KindoServerError {
        const message: string | undefined = json['message']
        const name: string | undefined = json['name']
        const context: string | undefined = json['context']

        // Specialise the error if possible
        switch (name) {
            case ServerSessionNotFoundException.exceptionName:
                return new ServerSessionNotFoundException({
                    message: message
                })
            case ServerSessionClosedException.exceptionName:
                return new ServerSessionClosedException({
                    message: message
                })
            case ServerSessionMissingException.exceptionName:
                return new ServerSessionMissingException({
                    message: message
                })
            case ServerFamilyEmailExistsException.exceptionName:
                return new ServerFamilyEmailExistsException({
                    message: message
                })
            default:
                return new KindoServerError({
                    rtype: json['rtype'],
                    message: message,
                    name: name,
                    context: context
                })
        }
    }
}

interface StandardSpecifiedServerErrorProps {
    message?: string
}


export class ServerFamilyEmailExistsException extends KindoServerError {
    static readonly exceptionName = "FamilyEmailExistsException"

    constructor({message}: StandardSpecifiedServerErrorProps) {
        super({
            rtype: "error",
            message: message,
            name: ServerFamilyEmailExistsException.exceptionName
        });
    }
}

export abstract class ServerSessionExceptionRequiresRefresh extends KindoServerError { }

export class ServerSessionNotFoundException extends ServerSessionExceptionRequiresRefresh {
    static readonly exceptionName = "SessionNotFoundException";

    constructor({message}: StandardSpecifiedServerErrorProps) {
        super({
            rtype: "error",
            message: message,
            name: ServerSessionNotFoundException.exceptionName
        })
    }}


export class ServerSessionClosedException extends ServerSessionExceptionRequiresRefresh {
    static readonly exceptionName = "SessionClosedException";

    constructor({message}: StandardSpecifiedServerErrorProps) {
        super({
            rtype: "error",
            message: message,
            name: ServerSessionClosedException.exceptionName
        })
    }
}


export class ServerSessionMissingException extends ServerSessionExceptionRequiresRefresh {
    static readonly exceptionName = "SessionMissingException";

    constructor({message}: StandardSpecifiedServerErrorProps) {
        super({
            rtype: "error",
            message: message,
            name: ServerSessionMissingException.exceptionName
        })
    }
}

